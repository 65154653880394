<template>
    <div class="tgju-widgets-row dashboard-widgets-technical-list dash-full-height">
        <div class="market-slider-tabs-box">
            <router-link :to="{name:'NewsIntro'}" class="mobile-back-btn">
                <i class="uil uil-angle-right-b"></i>
            </router-link>
            <div class="market-slider-tabs">
                <template>
                    <v-card>
                        <v-tabs v-model="tab">
                            <v-tabs-slider color="teal lighten-3"></v-tabs-slider>
                            <v-tab v-for="item in list" :key="item.id" @click="getCurrentMarketTab(item.id)">
                                <span>{{ item.name }}</span>
                            </v-tab>
                        </v-tabs>
                    </v-card>
                </template>
            </div>
        </div>     
        <!-- <NewsRow :cat="'اخبار-ویژه'" :count="6" :title="'اخبار ویژه'" :type="'slug'" /> -->
        <div class="market-news-list">
            <ul v-if="categoryId">
                <li v-for="(item, index) in categoryId.data" :key="index">
                    <router-link :to="{ name: 'ApplicationNewsContent' , params: { id: item.id, }, query: { subject: 'tgju-news-' + item.id }}">
                        <div class="news-list-image" :style="'background:url('+ ( item.image ? item.image : 'https://static.tgju.org/views/default/images/news/no-img.png' ) +')'"></div>
                        <div class="news-list-content">
                            <div class="news-list-title">
                                {{ item.title }}
                            </div>
                            <div class="news-list-info">
                                {{ item.jalali_created_at }}
                            </div>
                        </div>
                    </router-link>
                </li>
            </ul>
        </div>
        <infinite-loading @infinite="infiniteHandler">
            <div slot="spinner">
                <div class="widget-loading"></div>
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
        </infinite-loading>
    </div>
</template>

<script>
import InfiniteLoading from "vue-infinite-loading";

export default {
    name: 'ApplicationNews',
    components: {
        InfiniteLoading,
    },
    data() {
        return {
            categories: null,
            categoryId: null,
            tab: 'تحلیل-تکنیکال',
            loading: true,
            page: 2,
            list:[
                    {
                        id:'اخبار-ویژه',
                        name:'اخبار ویژه',
                    },
                    {
                        id:'تحلیل-بنیادین',
                        name:'تحلیل بنیادین',
                    },
                    {
                        id:'تحلیل-تکنیکال',
                        name:'تحلیل تکنیکال',
                    },
                    {
                        id:84008,
                        name:'گزارش بازارها',
                    },
                    {
                        id:93970,
                        name:'آکادمی',
                    },
                    {
                        id:93964,
                        name:'طلا و سکه',
                    },
                    {
                        id:93965,
                        name:'ارزی',
                    },
                    {
                        id:93966,
                        name:'ارزهای دیجیتال',
                    },
                    {
                        id:93967,
                        name:'نفت و انرژی',
                    },
                    {
                        id:93968,
                        name:'بورس',
                    },
                    {
                        id:83979,
                        name:'مسکن',
                    },
                    {
                        id:83980,
                        name:'خودرو',
                    },
                    {
                        id:93969,
                        name:'بانکی',
                    },
                    {
                        id:83986,
                        name:'تولید و تجارت',
                    },
                    {
                        id:83983,
                        name:'جهان',
                    },
                    {
                        id:83981,
                        name:'قیمت‌ها',
                    },
                    {
                        id:83984,
                        name:'فناوری',
                    }
                ],
        }
    },
    mounted() {
        // this.getCategories();
        this.getCategoryId();
        this.tab = this.$store.state.currentTabMarket;
        this.tab = this.list.findIndex(p => p.id == this.$route.params.category_id);
        Vue.use(InfiniteLoading, {
            slots: {
                noMore: "محتوا کاملاً بارگیری شدند", // you can pass a string value
            },
        });
    },
    methods: {
        hasNumber(string) {
            return /\d/.test(string);
        },
        getCurrentMarketTab(id) {
            this.$store.commit('setCurrentTabMarket', id);
            if(this.$route.params.category_id != id){
                this.$router.push({ name: "ApplicationNews", params: { category_id: id, },} );
            }
        },
                    
        getCategoryId($page) {
            this.loading = true;
            if(!this.hasNumber(this.$route.params.category_id)){
                this.$helpers
                .makeRequest("GET", `/news/category-tag`, { category_id: this.$route.params.category_id })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.categoryId = api_response.data.response.detail;
                        this.loading = false;
                    }
                });
            }else{
                this.$helpers
                .makeRequest("GET", `/news/category-id`, { category_id: this.$route.params.category_id })
                .then((api_response) => {
                    if (api_response.status == 200) {
                        this.categoryId = api_response.data.response.detail;
                        this.loading = false;
                    }
                });
            }
        }, 
        infiniteHandler($state) {
            if(!this.hasNumber(this.$route.params.category_id)){
                this.$helpers
                .makeRequest("GET", `/news/category-tag`, { category_id: this.$route.params.category_id , page: this.page})
                .then((api_response) => {
                    if (api_response.status == 200) {
                        if (api_response.data.response.detail.last_page > this.page) {
                            this.page += 1;
                            for (const [key, value] of Object.entries(api_response.data.response.detail.data)) {
                                this.categoryId.data.push(value);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                }); 
            }else{
                this.$helpers
                .makeRequest("GET", `/news/category-id`, { category_id: this.$route.params.category_id , page: this.page})
                .then((api_response) => {
                    if (api_response.status == 200) {
                        if (api_response.data.response.detail.last_page > this.page) {
                            this.page += 1;
                            for (const [key, value] of Object.entries(api_response.data.response.detail.data)) {
                                this.categoryId.data.push(value);
                            }
                            $state.loaded();
                        } else {
                            $state.complete();
                        }
                    }
                }); 
            }
        },
        // دکمه بازگشت
        back() {
            this.$router.go(-1);
        },
    },
}
</script>
